/* ============================================================================
  Typography
============================================================================== */

body,
input,
textarea,
button,
select,
label {
  font-size: 1rem;
  font-weight: 400;
  font-family: $neue-haas-unica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: 100%;
  line-height: 1.4;
  color: $color-body-text;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-weight: 700;
  font-family: $serenity;
  display: block;
  margin: 0 0 8px;
  line-height: 1.2;

  a {
    text-decoration: none;
    font-weight: inherit;
  }
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 1.5rem;
  &.alert,
  &.question {
    color: $color-red;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;

    i {
      font-size: 30px;
    }
  }
  &.success {
    color: $color-bright-green;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;

    i {
      font-size: 30px;
    }
  }

  &.question {
    color: $color-primary;
  }
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 18px;
}

h5,
h6 {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem 0;
}

a {
  display: inline-block;
  color: $color-purple;
  text-decoration: none;
  background: transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    color: darken($color-purple, 15%);
  }

  img {
    display: block;
  }
  &.alert-link {
    color: white;
    text-decoration: underline;
  }

  &.text-link {
    text-decoration: underline;

    &.purple {
      color: $color-purple;
    }
    &.warn {
      color: $color-red;
    }
  }
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button {
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}

@media only screen and (min-width: $small) {
  body {
    font-size: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  h4 {
    font-size: 22px;
  }

  h5,
  h6 {
    font-size: 18px;
  }

  p {
    margin: 0 0 1rem 0;
  }
}

em {
  font-style: italic;
}

small {
  font-size: 14px;
}

sup,
sub {
  position: relative;
  vertical-align: baseline;
}

a {
  text-decoration: none;
  font-weight: inherit;
}

.bold {
  font-weight: 900;
}
.underline {
  text-decoration: underline;
}

.override {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: unset;
  }
}
