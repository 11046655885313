@import "../helpers/variables";

/* ============================================================================
  Icons
============================================================================== */

[class^="icon-"],
[class*=" icon-"] {
  font-family: $font-awesome !important;
  speak: none;
  font-weight: 900;
  line-height: 1;
}

.icon-messenger::before {
  content: "\f39f";
}
.icon-category-arrow::before {
  content: "\f054";
}
.icon-doc::before {
  content: "\f15b";
}
.icon-search::before {
  content: "\f002";
}
.icon-paw-2::before {
  content: "\f1b0";
}
.icon-close-circle::before {
  content: "\f057";
}
.icon-large-arrow-left::before {
  content: "\f30a";
}
.icon-large-arrow-right::before {
  content: "\f30b";
}
.icon-check::before {
  content: "\f00c";
}
.icon-plus-thin::before {
  content: "\f067";
}
.icon-arrow-left::before {
  content: "\f30a";
}
.icon-arrow-right-thin::before {
  content: "\f30b";
}
.icon-remove::before {
  content: "\f2ed";
}
.icon-close-thin::before {
  content: "\f00d";
}
.icon-arrow-right::before {
  content: "\f30b";
}
.icon-chevron-up::before {
  content: "\f077";
}
.icon-paw::before {
  content: "\f1b0";
}
.icon-cart::before {
  content: "\f291";
}
.icon-menu::before {
  content: "\f0c9";
}
.icon-close::before {
  content: "\f00d";
}
.icon-chevron-down::before {
  content: "\f078";
}
.icon-plus::before {
  content: "\f067";
}
.icon-minus::before {
  content: "\f068";
}
.icon-instagram::before {
  content: "\f16d";
}
.icon-facebook::before {
  content: "\f39e";
}
.icon-twitter::before {
  content: "\f099";
}
.icon-printer::before {
  content: "\f02f";
}
.icon-link::before {
  content: "\f0c1";
}
.icon-facebook-box::before {
  content: "\f082";
}
.rainy_day {
  background-color: white;
  mask-image: url("/svgs/icon-rain-cloud.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  height: 30px;
  width: 30px;
  display: block;
}
@mixin paw($color: $color-primary, $size: 30px) {
  background-color: $color;
  mask-image: url("/svgs/icon-nav-paw.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  width: $size;
  height: $size;
}
