article,
section,
div {
  &.loading {
    min-height: 50vh;
    display: grid;
    background-image: url("/svgs/Blue Cat-2s-200px.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}
