@import "@styles/helpers/variables";

.quantitySelector {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cartQuantitySelector {
  gap: 1rem;

  @media only screen and (max-width: $small) {
    justify-content: flex-start;
  }
}

.colDir {
  flex-direction: column;

  div {
    margin-top: 5px;
  }

  label {
    display: flex !important;
    justify-content: center;

    &.accSelector {
      flex-direction: column;
    }
  }
}

// .qInputWrapper {
//   margin: 0 20px;
// }

.fSelectorCart {
  order: 2;
  display: flex;
  align-items: center;

  .authoShipP {
    font-size: 1rem !important;
  }
}
