@import "@styles/helpers/variables";

.cartFooter {
  width: 100%;
  bottom: 0;
  right: 0;
  background-color: #4b40c9;
  color: white;

  &.showUpsell {
    @media screen and (max-width: $large) {
      padding-bottom: 50px;
    }
  }

  a {
    width: 100%;
  }

  .cartTotalCheckout {
    width: 100%;
    padding: 2rem 3rem 0.5rem;
    display: flex;

    @media screen and (max-width: $large) {
      flex-direction: column;
    }

    @media screen and (max-width: $xsmall) {
      padding: 20px 20px 0px 20px;
    }

    .cartFooterSection {
      width: 100%;

      @media screen and (min-width: $large) {
        width: 50%;
      }
    }

    .cartTotal {
      float: left;

      .totalSection {
        width: 100%;
        float: left;
        font-size: 1rem;
        line-height: 0.5rem;

        @media screen and (min-width: $large) {
          line-height: 1rem;
        }
      }

      .subTotal {
        float: right;
      }

      .cartSubTotal {
        font-size: 1.25rem;
        font-family: $neue-haas-unica-medium;
        margin-top: 1rem;

        @media screen and (min-width: $large) {
          font-size: 1.5rem;
        }

        .totalDiscount {
          font-size: 1rem;
          text-decoration: line-through;
        }
      }
    }

    .cartCheckout {
      text-align: center;
      margin: 1rem auto 0;

      @media screen and (max-width: $large) {
        width: 50%;
      }

      @media screen and (max-width: $medium) {
        width: 80%;
      }

      @media screen and (max-width: $small) {
        width: 100%;
      }

      @media screen and (min-width: $large) {
        padding-left: 32px;
        text-align: right;
      }

      .cartCheckoutP {
        margin-top: 14px;
        text-align: center;
        font-size: 0.75rem;
        opacity: 0.75;
        color: white;
      }
    }
  }
}
