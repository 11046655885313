.mini-footer {
  background-color: $color-grey;
  padding: 3rem 0 3rem;

  .logo {
    width: 216px;
    height: 68px;
    background: black;
    mask-image: url("/svgs/kpc_logo.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
    margin: 0 auto 3rem;
  }

  section {
    display: grid;
    place-items: center;
  }

  .help {
    font-size: 1.125rem;
    font-weight: bold;
  }

  a {
    color: black;
    &:hover {
      color: black;
      opacity: 0.5;
    }
  }

  .links {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  .social {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    line-height: 1;

    a {
      font-size: 1.5rem;
    }
  }

  .copyright {
    font-size: 0.9rem;
    opacity: 0.5;
    color: black;
  }

  @media only screen and (min-width: $medium) {
    .logo {
      margin: 0 auto;
    }
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    padding: 3rem 0 5rem;
  }
}

.footer {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 0.5rem;
    }
  }
  .grid {
    display: grid;
    align-items: center;
  }
  .kpc_logo {
    width: 216px;
    height: 68px;
    background: black;
    mask-image: url("/svgs/kpc_logo.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
  }
  @media only screen and (max-width: $small) {
    .grid {
      display: none;
    }
    h2 {
      cursor: pointer;
      padding-left: 0.5rem;
      &::after {
        font-size: 12px;
        line-height: 1;
        font-family: $font-awesome;
        font-weight: 900;
        padding-left: 0.5rem;
        content: "\f078";
      }
    }
    ul {
      display: none;
      &.active {
        display: block;
      }
      li {
        text-align: center;
        font-size: 1rem;
      }
    }
    margin-bottom: 2rem;
  }
}

.full-footer {
  background-color: $color-grey;
  padding: 1rem 0;
  padding-bottom: 60px;

  .grid {
    display: grid;
    align-items: center;
  }

  .logo {
    width: 216px;
    height: 68px;
    background: black;
    mask-image: url("/svgs/kpc_logo.svg");
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: $color-body-text;
  }

  .bottom {
    text-align: center;
    margin: 0 auto;
    p {
      margin: 0;
    }
    small {
      display: block;
      padding: 1rem 0;
      border-top: 1px solid $color-med-grey;
      border-bottom: 1px solid $color-med-grey;
      font-size: 1rem;
      margin: 0.5rem auto;
      max-width: 600px;
    }
  }

  .copyright {
    font-size: 0.9rem;
    opacity: 0.5;
    color: black;
  }

  @media only screen and (max-width: $small) {
    .grid {
      display: none;
    }
    .flex-wrap {
      display: grid;
      grid-gap: 0.5rem;
      button {
        width: 100%;
        display: block;
      }
      h2 {
        cursor: pointer;
        padding-left: 0.5rem;
        &::after {
          font-size: 12px;
          line-height: 1;
          font-family: $font-awesome;
          font-weight: 900;
          padding-left: 0.5rem;
          content: "\f078";
        }
      }
      ul {
        display: none;
        &.active {
          display: block;
        }
        li {
          text-align: center;
          font-size: 1rem;
        }
      }
      margin-bottom: 2rem;
    }
    .bottom {
      max-width: 350px;
    }
  }

  @media only screen and (min-width: $small) {
    .flex-wrap {
      gap: 1rem;
      justify-content: space-around;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 17px;
    }
  }
}
