@import "@styles/helpers/variables";

.cartItem {
  position: relative;
  display: block;
  padding: 24px;
  margin-bottom: 1rem;
  border-radius: 32px;
  background-color: $color-background-lt-green;

  @media screen and (min-width: $large) {
    max-width: 760px;
  }

  @media only screen and (max-width: $small) {
    padding: 16px;
  }
}

// Top 3 Elements (Image, Description, Price) //
.cartGrid {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 2.5%;

  @media screen and (max-width: $small) {
    grid-template-columns: 125px 200px 14px;
    column-gap: 1.5%;
  }

  @media screen and (max-width: $xsmall) {
    grid-template-columns: 100px 185px 14px;
    column-gap: 1.5%;
  }

  @media screen and (max-width: $xxsmall) {
    grid-template-columns: 75px 160px 14px;
    column-gap: 1.5%;
  }
}

// Image //
.cartItemImage {
  background-color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 150px;
  height: 150px;

  @media screen and (max-width: $small) {
    width: 125px;
    height: 125px;
  }

  @media screen and (max-width: $xsmall) {
    width: 100px;
    height: 100px;
  }

  @media screen and (max-width: $xxsmall) {
    width: 75px;
    height: 75px;
  }

  .image {
    max-width: 150px;
    max-height: 150px;
    width: auto;
    object-fit: cover;

    @media screen and (max-width: $small) {
      width: 125px;
      height: 125px;
    }

    @media screen and (max-width: $xsmall) {
      width: 100px;
      height: 100px;
    }

    @media screen and (max-width: $xxsmall) {
      width: 75px;
      height: 75px;
    }
  }
}

// Description and Variant(s) //
.cartItemDescription {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: $small) {
    margin-left: 5px;
  }

  .cartItemH4 {
    font-size: 1.125rem;
    font-family: $neue-haas-unica-medium;

    @media screen and (max-width: $small) {
      font-size: 0.9rem;
      margin: 0;
    }

    @media screen and (max-width: $xsmall) {
      font-size: 0.8rem;
      margin: 0;
    }

    @media screen and (max-width: $xxsmall) {
      overflow: hidden;
    }
  }

  p {
    color: $price-dark-grey;
    line-height: 1.6;
    font-size: 0.85rem;
    margin: 0;

    @media screen and (max-width: $xsmall) {
      font-size: 0.75rem;
      margin-bottom: 3px;
    }
  }

  .cartItemPrice {
    font-size: 1.25rem;
    font-family: $neue-haas-unica-medium;

    @media screen and (max-width: $xsmall) {
      font-size: 1rem;
    }
  }
}

.imageLink {
  color: $color-body-text;
}

// Price and Remove Button //
.cartItemPriceRemove {
  width: 100%;
  text-align: right;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: $small) {
    flex-direction: column-reverse;
  }

  .cartItemRemove {
    color: $color-deep-purple;

    .textRemove {
      font-size: 1rem;
    }

    .iconRemove {
      margin-left: 5px;
      font-size: 1.125rem;

      @media only screen and (max-width: $small) {
        display: relative;
        right: 5px;
        top: 5px;
      }
    }
  }
  .cartItemPrice {
    font-size: 1.5rem;
    font-family: $neue-haas-unica-medium;
  }
}

// Quantity Selector and Autoship //
.cartItemQuantityAutoship {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: $small) {
    transform: scale(0.8) translateX(-1.5rem);
  }
}

.iconRemove {
  margin-left: 5px;
  font-size: 0.9rem;

  @media only screen and (max-width: $xxsmall) {
    position: relative;
    right: -2.5px;
    top: -2.5px;
  }
}
