.yotpo-display-wrapper {
  visibility: visible !important;
}
.yotpo-bottomline {
  display: inline-block;
  margin: 0 auto;

  font-size: 14px;
  .yotpo-sr-bottom-line-right-panel {
    display: none !important;
  }
}
.item-review-bar {
  display: none;
  position: relative;
  .yotpo-display-wrapper {
    position: absolute;
    left: 0;
    right: 0;
  }
  .standalone-bottomline {
    display: grid;
    place-content: center;
  }
  a.text-m {
    display: none !important;
  }
  .yotpo-bottomline {
    display: block;
  }
}

.yotpo-stars {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0.25rem;
  .yotpo-icon-empty-star {
    &::after {
      font-size: 1rem;
      line-height: normal;
      font-family: $font-awesome;
      font-weight: 500;
      content: "\f005";
    }
    color: gold;
  }
  .yotpo-icon-half-star {
    &::after {
      font-size: 1rem;
      line-height: normal;
      font-family: $font-awesome;
      font-weight: 900;
      content: "\f5c0";
    }
    color: gold;
  }
  .yotpo-icon-star {
    &::after {
      font-size: 1rem;
      line-height: normal;
      font-family: $font-awesome;
      font-weight: 900;
      content: "\f005";
    }
    color: gold;
  }
}
.notext {
  .yotpo-sr-bottom-line-text {
    display: none;
  }
  .yotpo-reviews-star-ratings-widget {
    justify-content: center !important;
  }
}
