/* ============================================================================
  Forms
============================================================================== */

@include media-query($max, $medium) {
  input,
  textarea {
    font-size: 1rem;
  }
}

input,
textarea {
  user-select: text;
  &::placeholder {
    color: $color-med-grey;
  }
}

button,
input,
textarea {
  appearance: none;
}

button {
  background: none;
  border: none;
  display: inline-block;
  cursor: pointer;
}

fieldset {
  display: grid;
  grid-gap: 1rem;
  border: none;
  padding: 0;
  &.nogrid {
    display: block;
    grid-gap: 0;
  }
}

legend {
  @extend h3;
  border: 0;
  padding: 0;
  margin: 0 0 0.5rem;
  font-size: 1.25rem;
}

button,
input[type="submit"] {
  cursor: pointer;
}

input,
textarea,
select,
.multi-input {
  border: 2px solid $color-primary;
  border-radius: $border-radius;
  max-width: 100%;
  padding: 8px 10px;

  &[disabled],
  &.disabled {
    cursor: default;
    border-color: $color-disabled;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    border: 2px solid $color-purple;
    //box-shadow: 0 0 4px $color-purple;
  }

  &.input-full {
    width: 100%;
  }
}

textarea {
  border-radius: 0;
  min-height: 96px;
}

.sqr {
  select {
    border-radius: 0;
  }
}

.multi-input {
  display: grid;
  grid-template-columns: 25px 10px auto 50px;
  grid-gap: 4px;
  align-items: center;
  &.error {
    border-color: $color-red;
  }
  span {
    font-size: 1.5rem;
    line-height: 20px !important;
    color: $color-med-grey;
  }
  input {
    padding: 0;
    border: none;
    border-radius: 0;
    width: min-content;
  }
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
  margin: 0 8px 0 0;
  padding: 0;
  width: auto;
}

input[type="checkbox"] {
  appearance: checkbox;
  appearance: checkbox;
}

input[type="radio"] {
  appearance: radio;
  appearance: radio;
}

input[type="image"] {
  padding-left: 0;
  padding-right: 0;
}

select {
  cursor: pointer;
  appearance: none;
  padding: 8px 28px 8px 1rem;
  text-overflow: "";
  width: auto;
  background-image: url("/svgs/select-icon.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-color: white;
  border: 2px solid $color-primary;
}

select::-ms-expand {
  display: none;
}

label[for] {
  cursor: pointer;
}

label {
  &.hide {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
    font-size: 0;
  }
}

form {
  input,
  textarea,
  select {
    width: 100%;
  }
}

.form-vertical {
  input,
  select,
  textarea,
  button {
    display: block;
    margin-bottom: 10px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: inline-block;
  }

  p {
    display: block;

    &.stepper-only {
      display: none;
    }
  }
}

.form-inline {
  position: relative;
  display: table;

  input,
  div,
  .btn {
    display: table-cell;
    vertical-align: middle;
    margin: 0;
    height: 42px;
  }

  input {
    width: 100%;
  }

  div {
    position: relative;
    white-space: nowrap;
    width: 1%;
    padding: 0;
  }
}

.message {
  padding: 8px;
  border: 2px solid $color-red;
  border-radius: 8px;
  text-align: center;
}

.quantity-selector {
  .price {
    h4 {
      font-family: $neue-haas-unica;
      font-size: 1.125rem;
      margin: 0;

      s {
        color: $color-med-grey;
        margin-right: 0.5rem;
      }

      span {
        color: $color-red;
      }
    }
  }
}

.thinInputs {
  input,
  textarea,
  .multi-input {
    padding: 4px 10px;
  }
  select {
    padding: 4px 28px 4px 1rem;
  }
}

.account .f-selector {
  margin-top: 1rem;
}
.f-selector {
  @mixin beforeAnimation {
    -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
    transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  }

  @mixin afterAnimation {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
      0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
      0 3px 3px hsla(0, 0%, 0%, 0.05);
    -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
    transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  }

  @mixin inactiveMixin {
    content: "";
    position: absolute;
    display: block;
  }

  .toggleWrapper {
    $t-width: 50px;
    $t-height: 30px;

    label {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 0.5rem;
      place-items: center;

      p {
        margin: 0;
        font-size: 1rem;
        line-height: 1;
      }
    }

    input {
      opacity: 0;
      position: absolute;
      & + span {
        position: relative;
        display: inline-block;
        user-select: none;
        -moz-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
        -webkit-tap-highlight-color: transparent;
        height: 32px;
        width: 54px;
        border: 2px solid $color-med-grey;
        border-radius: 60px;
        background: $color-grey;

        &:before {
          @include inactiveMixin;
          @include beforeAnimation;
          height: 28px;
          width: 50px;
          top: 0;
          left: 0;
          border-radius: 30px;
        }

        &:after {
          @include inactiveMixin;
          @include afterAnimation;
          background: white;
          height: 26px;
          width: 26px;
          top: 0;
          left: 0px;
          border-radius: 60px;
          border: 1px solid $color-grey;
        }
      }
      // When Active
      &:checked {
        & + span {
          border: 2px solid $color-deep-purple;
          &:before {
            background: lighten($color-deep-purple, 25%);
            -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
            -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
            -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
            transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
          }
          &:after {
            left: 52px - 30px;
          }
        }
      }
    }
  }
}

.f-selector-stepper {
  display: flex;
  margin: 1rem auto;
  width: fit-content;

  button {
    background-color: $color-med-grey;
    color: white;
    white-space: nowrap;
    margin: 0 0.5rem;
    padding: 0 1rem;

    &.active {
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

@include media-query($min, $medium) {
  .f-selector {
    .toggleWrapper {
      label {
        p {
          font-size: 1.25rem;
        }
      }
    }
  }
}

.q-selector {
  position: relative;
  color: $color-primary;
  max-width: 140px;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  border: 2px solid $color-primary;
  border-radius: $border-radius;
  background: $color-primary;
  overflow: hidden;

  input {
    text-align: center;
    -moz-appearance: textfield; // stylelint-disable-line'
    border: none;
    border-radius: 0;
    appearance: none;
    width: 100%;
    color: $color-primary;
    font-size: 1rem;
    font-family: $neue-haas-unica-medium;
    font-weight: bold;
    background: white;
  }

  button {
    display: block;
    padding: 5px;
    font-size: 0.75rem;
    color: white;

    &.customQButton {
      font-size: 0.6rem;
      width: 29px;
    }

    &.disabled {
      background-color: $color-med-grey;
    }
  }

  @media only screen and (min-width: $small) {
    grid-template-columns: 50px 1fr 50px;
    max-width: 170px;
    min-width: 150px;
    input {
      font-size: 1.4rem;
    }
  }
}

td.qty {
  line-height: 38px + 16px;
}
@media only screen and (min-width: $small) {
  td.qty {
    line-height: 45px + 16px;
  }
}
