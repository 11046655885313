@import "@styles/helpers/variables";

.qSelector {
  background-color: $color-white;
  border-radius: $border-radius;
  border: 1px solid $color-deep-purple;
  padding: 3px;
  order: 1;
  min-width: 100px;
  max-width: 120px;
  grid-template-columns: 35px 1fr 35px;
  display: grid;
}

.customQSelector {
  grid-template-columns: 30px 1fr 29px;
}

.qBtn {
  background-color: $color-deep-purple;
  color: $color-white;
  border-radius: 50%;
  width: 100%;
}

.qIcon {
  line-height: 2;
}

.qInput {
  font-size: 1rem !important;
  text-align: center;
  border: none;
  padding: 0;
  width: 100%;
  color: $color-black !important;

  &:focus {
    border: none;
  }
}
