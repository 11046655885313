.homepageHero {
  display: block;
  position: relative;
  overflow: hidden;
  background-position: center 50%;
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (min-width: $xxsmall) and (max-width: $pre-xsmall) {
    height: 440px;
    background-position: center 100% !important;
  }

  @media only screen and (min-width: $xsmall) and (max-width: $pre-smaller) {
    height: 480px;
    background-position: center 100% !important;
  }

  @media only screen and (min-width: $smaller) and (max-width: $pre-small) {
    height: 522px;
    background-position: center 100% !important;
  }

  @media only screen and (min-width: $small) and (max-width: $pre-medium) {
    background-position: 70% bottom;
  }

  // @media only screen and (min-width: $medium) and (max-width: $pre-large) {
  //   background-position: center 75%;
  // }

  // @media only screen and (min-width: $large) and (max-width: $pre-larger) {
  //   background-position: center 75%;
  // }

  // @media only screen and (min-width: $larger) and (max-width: $pre-xlarge) {
  //   background-position: center 75%;
  // }

  // @media only screen and (min-width: $xlarge) and (max-width: $pre-xxlarge) {
  //   background-position: center 75%;
  // }

  // @media only screen and (min-width: $xxlarge) and (max-width: $pre-xxxlarge) {
  //   background-position: center 75%;
  // }

  @media only screen and (min-width: $xxxlarge) {
    background-position: center 48%;
  }

  article {
    max-width: 100vw;
    margin: 0 auto;
    place-items: center;
    position: initial;
    padding-bottom: 1rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 1rem;

    @media only screen and (max-width: $xxsmall) {
      padding: 1rem 0;
    }

    @media only screen and (min-width: $small) {
      padding-top: 0;
      grid-template-columns: 48% 1fr;
      grid-template-rows: 100%;
      place-items: center self-start;
      padding: 0 2rem;
    }

    @media only screen and (min-width: $small) and (max-width: $medium) {
      padding-bottom: 0;
      grid-template-columns: 60% 1fr;
    }

    @media only screen and (min-width: $medium) and (max-width: $pre-large) {
      padding-bottom: 0;
      grid-template-columns: 40% 1fr;
    }

    @media only screen and (min-width: $large) {
      padding-bottom: 0;
      grid-template-columns: 42% 1fr;
      max-width: $site-width;
      min-width: 1068px;
    }
  }

  h1 {
    font-size: 2.5rem;
    line-height: 0.95;

    @media only screen and (max-width: $small) {
      margin: auto;
      max-width: 300px;
    }

    @media only screen and (max-width: $small) {
      font-size: 2rem;
    }

    @media only screen and (min-width: $small) {
      text-align: left;
    }

    @media only screen and (min-width: $large) {
      font-size: 3.5rem;
      margin-bottom: 1.5rem;
    }
  }

  ul {
    font-size: 1rem;
    line-height: 1.2;
    margin: 1rem auto;
    margin-bottom: 1rem;
    text-align: left !important;
    padding-left: 0;
    width: max-content;

    @media only screen and (max-width: $xsmall) {
      width: max-content;
      margin: 1rem auto;
    }

    @media only screen and (min-width: $small) {
      text-align: left;
      font-size: calc-rem(16px);
      padding-right: 10px;
      margin: 0 0 25px;
      max-width: 385px;
    }

    @media only screen and (min-width: $large) {
      font-size: calc-rem(20px);
      line-height: 2rem;
      max-width: 385px;
      margin: 0 0 25px;
      padding-right: 0;
    }

    li {
      margin: 5px 0;
      display: flex;
      align-items: center;
      line-height: 1rem;

      @media screen and (max-width: $small) {
        height: 22px;
      }
    }
  }
}

.heroStars {
  span,
  a {
    margin: 0 0.5rem;
  }

  @media only screen and (max-width: $small) {
    margin: 0;
    text-align: center;
  }
}

.heroContent {
  text-align: center;
  position: relative;

  @media only screen and (min-width: $small) {
    text-align: left;
    padding: 1.5rem 0;
    justify-self: start;
  }
}

.hideMobile {
  @media only screen and (max-width: $small) {
    visibility: hidden;
    height: 0;
  }
}

.showMobile {
  visibility: hidden;
  height: 0;

  @media only screen and (max-width: $small) {
    visibility: visible;
    height: auto;
    margin: 0 auto 1rem;
  }
}
