@import "@styles/helpers/variables";
@import "@styles/helpers/mixins";

.impact {
  h2 {
    font-size: 3rem;
    line-height: 3.6rem;
    font-family: $serenity;
    margin: 3rem 0 16px;

    @media screen and (max-width: $medium) {
      margin: 2rem 0 16px;
    }
  }

  p {
    margin: 0 auto;
    font-size: 1.25rem;
    max-width: 600px;
  }
}
