@import "@styles/helpers/variables";

// Cart Header //
.cartHeader {
  padding: 32px 0;
  text-align: center;
  position: sticky;
  top: 0px;

  @media screen and (max-width: $small) {
    padding: 0;
  }

  .cartHeaderH1 {
    font-size: 3.3rem;
  }

  .cartCloseBtn {
    top: 20px !important;
    right: 20px !important;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .showUpsell {
    @media screen and (max-width: $small) {
      top: 10px !important;
      right: 10px !important;
    }
  }
}
