@import "@styles/helpers/variables";

.yotpo-widget {
  *:focus {
    box-shadow: none !important;
  }
}

.yotpo-widget-campaign-widget .yotpo-widget-campaign-widget-container {
  padding: 0 !important;
  max-width: $site-width !important;
  &:focus,
  *:focus {
    box-shadow: none !important;
  }
}

.yotpo-widget-my-rewards-widget {
  &.yotpo-info {
    display: none;
  }

  .yotpo-logged-in-view {
    .yotpo-primary-button,
    .yotpo-secondary-button {
      padding: o;
    }
    .yotpo-action-buttons-tile {
      grid-gap: 1rem;
    }
  }
}

.yotpo-widget-referral-widget {
  .yotpo-tile {
    min-height: fit-content;
    margin: 2rem auto;
  }
  .yotpo-header-text {
    display: none;
    font-family: $serenity !important;
    padding-bottom: 2rem;
  }
}

.logged_out {
  .yotpo-rewards-guide-tile-wrapper {
    display: none;
  }
  .yotpo-widget-referral-widget {
    display: none;
  }
}

.yotpo-background.yotpo-center-background {
  height: auto !important;
}

.yotpo-coupons-redemption-widget {
  max-width: $site-width !important;
  margin: auto !important;
}
