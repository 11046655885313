//Product Page
.products-section {
  padding-bottom: 4rem;

  .category {
    align-items: center;
    border-bottom: 1px solid $color-med-grey;
    margin-top: 10px;

    button {
      width: 100%;
    }

    h2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }

    img {
      width: 80%;
      display: block;
      margin: auto;
    }

    span {
      display: block;
      margin-bottom: 20px;
    }
  }
}

.product-variant-dropdown {
  width: 100%;
  margin-bottom: 8px;
  .form-flex {
    grid-gap: 1rem;
    justify-content: flex-start;
  }

  select {
    border: none;
    float: right;
    border: 1px solid $color-deep-purple;
    border-radius: 30px !important;
    background-position: right 20px center !important;
  }
}

.productImgLrg:hover {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.form-flex {
  @media screen and (max-width: 956px) {
    flex-wrap: nowrap !important;
  }
}

.product-main-container .yotpo {
  margin-bottom: 10px;
}

.compare-similar {
  .yotpo .text-m {
    display: block;
    margin-top: 5px;
  }

  .yotpo .yotpo-stars {
    display: flex;
    justify-content: center;
  }

  .yotpo .yotpo-icon-star {
    font-size: 12px !important;
  }

  .yotpo .pull-left {
    float: none;
    text-align: center;
    display: block !important;
  }
}

//YOTPO
.products-section .text-m {
  color: $color-deep-purple !important;
  text-decoration: underline !important;
}

.reviews-section {
  .yotpo {
    .write-review-button {
      background-color: $color-primary;
      border-radius: 30px;
      padding: 9px 48px !important;

      &:hover {
        background-color: $color-btn-primary-hover;
      }
    }

    .yotpo-icon-button-text {
      font-family: $neue-haas-unica-medium;
    }

    .yotpo-review {
      color: $color-body-text;
      background-color: $color-background-lt-green;
      border-radius: 30px;
      border-top: none;
      margin: 20px 0;
      padding: 40px 40px 20px;

      .rating-star {
        color: $color-primary !important;
      }
    }

    .yotpo-main {
      margin-left: 0 !important;
      margin-top: 20px !important;

      .content-title {
        font-family: $neue-haas-unica-medium;
        font-size: 16px;
      }
    }

    .yotpo-user-name {
      color: $color-black !important;
      // position: absolute;
      // margin-top: 100px;
    }

    .yotpo-icon-write,
    .yotpo-icon-profile,
    .yotpo-footer,
    .yotpo-user-title {
      display: none !important;
    }
  }
}
