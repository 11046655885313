@import "@styles/helpers/variables";

.cartIsEmptyContainer {
  max-width: 1313px;
  margin: auto;
  position: relative;
  padding: 0 2rem;
  text-align: center;

  @media screen and (max-width: $xxsmall) {
    padding: 0 0.5rem;
  }
}
