@import "@styles/helpers/variables";

.cartUpsellItem {
  position: relative;
  border: 2px solid transparent;
  border-radius: 32px;
  padding-bottom: 1rem;

  &:hover {
    background-color: $color-white;
    border: 2px solid $color-primary;

    .upsellPrice {
      display: none;
    }

    .upsellBtn {
      display: block;
    }
  }

  .cartUpsellItemImg {
    position: relative;
    padding: 10px;
    background-color: $color-white;
    border-radius: 32px;
    margin-bottom: 12px;

    .cartUpsellImgWrapper {
      position: relative;
      overflow: hidden;
      width: 150px;
      margin: 0 auto;
    }
  }

  .upsellTitle {
    font-family: $neue-haas-unica-medium;
    font-size: 1rem;
    line-height: 1.125rem;
    padding: 0 10px;
    margin-bottom: 9px;
  }

  .upsellPrice,
  .upsellBtn {
    position: relative;
    margin: auto;
    font-family: $neue-haas-unica-medium;
    font-size: 0.875rem;
    width: 85%;
    padding: 7px 0;
    line-height: normal;
  }
  .upsellPrice {
    padding: 12px 0 8px;
  }
  .upsellBtn {
    display: none;
  }
}
