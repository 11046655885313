.collectionItem {
  .shopNowBtn {
    display: none !important;
  }
  &:hover {
    .shopNowBtn {
      display: flex !important;
    }
    .priceBlock {
      opacity: 0 !important;
    }
  }
}
