@import "@styles/helpers/variables";

// Cart Body //
.cartBody {
  overflow-y: auto;
  padding: 0 24px 24px;

  @media only screen and (max-width: $small) {
    padding: 0 12px 12px;
  }
}

.cartItemsWrapper {
  display: flex;
  flex-direction: column;
}

.continueLink {
  color: $color-black;
  text-decoration: underline;
}

// Empty Cart Button //
.emptyCart {
  text-align: right;
  padding-right: 24px;
  margin-bottom: 1rem;

  .emptyCartBtn {
    color: $color-deep-purple;
    font-family: $neue-haas-unica-medium;

    &:hover {
      color: $color-btn-primary-hover;
    }
  }
}
