@import "@styles/helpers/variables";

.notFoundContainer {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: $medium) {
    flex-direction: column;
  }

  .imgContainer {
    order: 1;
    width: 50%;
    text-align: right;
    padding: 4rem 2rem;
    padding-right: 0;

    @media screen and (max-width: $medium) {
      order: 2;
      width: 100%;
      padding: 0;
      text-align: center;
    }

    @media screen and (max-width: $small) {
      position: relative;
      overflow-x: hidden;
      height: 400px;
    }

    @media screen and (max-width: $xsmall) {
      height: 350px;
    }

    @media screen and (max-width: $xxsmall) {
      height: 300px;
    }

    img {
      width: 100%;
      max-width: 650px;
      height: auto;

      @media screen and (max-width: $small) {
        width: 110%;
        position: absolute;
        top: 0;
        left: -5%;
      }
    }
  }

  .textContainer {
    order: 2;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-deep-purple;
    padding-left: 5rem;
    padding-right: 5rem;
    max-height: 800px;

    @media screen and (max-width: $medium) {
      order: 1;
      padding: 2rem 0;
      margin: 0 auto;
      text-align: left;
      max-width: 260px;
      width: 90%;
    }

    h1 {
      font-size: 15rem;
      line-height: 13rem;
      font-family: $serenity;
      margin-bottom: 2rem;

      @media screen and (max-width: $medium) {
        font-size: 7rem;
        line-height: 7rem;
        text-align: center;
      }
    }

    h2 {
      font-size: 2rem;
      font-family: $neue-haas-unica;
      margin-bottom: -1rem;

      @media screen and (max-width: $medium) {
        font-size: 1.2rem;
        text-align: left;
        padding-left: 3rem;
      }
    }

    p {
      color: $color-black;
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      max-width: 500px;
    }

    .link {
      width: 50%;
      padding: 0.5rem 0;
      text-decoration: underline;
    }

    .alignRight {
      text-align: right;
    }
  }
}
