@import "@styles/helpers/variables";

.help_me {
  position: fixed;
  right: 1rem;
  z-index: 2000;
}

.helpBottom {
  bottom: 1rem;
}

.helpTop {
  top: 75px;
  position: absolute;
}

.btnBeacon {
  display: grid !important;
  position: absolute;
  bottom: 20px;
  right: -5px;
  place-content: center;
  width: 50px !important;
  height: 50px;
  color: $color-primary !important;
  background-color: white !important;
  border: 2px solid $color-primary !important;
  border-radius: 50% !important;
  font-size: 1.75rem !important;
}
