@import "@styles/fonts/icons";

.form-builder {
  padding-bottom: 1rem;

  &.topSpace {
    padding-top: 1rem;
  }

  &.thickBottom {
    border-bottom-width: 2px;
  }
  &.noPad {
    padding-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: inline;
    position: relative;
    &.hidden {
      display: none;
    }

    &.margin-bottom {
      input {
        margin-bottom: 1rem;
      }
    }
  }

  &.spaceBetween {
    ul {
      padding-bottom: 12.5%;
    }
  }

  div.error {
    margin-top: 1rem;
    text-align: center;
    li {
      display: block;
    }
  }

  legend {
    padding: 0;
    font-size: 1.25rem;
  }

  &.notActive {
    .in-line {
      display: inline-block;
      &.comma-after > span::after {
        content: ",";
        padding-right: 0.25rem;
      }
      &.space {
        padding-right: 0.25rem;
      }
      span {
        display: inline-block;
      }
    }
  }

  .hide-title {
    legend {
      visibility: hidden;
      height: 0;
      margin: 0;
      padding: 0;
      font-size: 0;
    }
  }

  input,
  span {
    display: block;
    max-width: 400px;
  }

  span {
    line-height: 1.4;
  }

  label {
    margin-bottom: 0.5rem;
    display: block;
    &.hide {
      visibility: hidden;
      height: 0;
      margin: 0;
      padding: 0;
      font-size: 0;
    }
  }
  select,
  input {
    &.primary {
      border-color: $color-primary;
      color: $color-body-text;
    }

    &.error {
      border-color: $color-red;
      color: $color-body-text;
      font-weight: normal;
    }
  }
  /*
  &.required {
    input {
      border-style: dashed;
      &.required {
        border-style: solid;
      }
    }
  }
*/
  .checkbox-paw {
    label {
      order: 1;
      margin: 0;
      display: flex;
      grid-gap: 8px;
      font-size: 14px;
      align-items: center;
      .radio-btn {
        height: 1.75rem;
        width: 1.75rem;
        i {
          color: $color-purple;
          background: white;
          padding: 4px;
          border: 2px solid $color-purple;
          min-width: 1.75rem;
          min-height: 1.75rem;
          display: flex;
        }
        &.checked {
          i::after {
            @include paw($color-purple, 16px);
            content: "";
            display: block;
          }
        }
      }
    }
    input {
      visibility: hidden;
      height: 0;
      margin: 0 0 0.5rem;
    }
  }
  .radio-paw {
    label {
      display: flex;
      grid-gap: 8px;
      font-size: 14px;
      width: fit-content;
      align-items: center;

      .radio-btn {
        height: 32px;
        width: 32px;
        i {
          color: $color-primary;
          background: white;
          border-radius: 50%;
          padding: 4px;
          border: 2px solid $color-primary;
          min-width: 32px;
          min-height: 32px;
          display: flex;
        }
        &.checked {
          i::after {
            @include paw($color-primary, 20px);
            content: "";
            display: block;
          }
        }
      }
    }
    input {
      visibility: hidden;
      height: 0;
      margin: 0 0 0.5rem;
    }
  }

  .form-checkbox {
    label {
      order: 1;
      margin: 0;
      display: flex;
      grid-gap: 8px;
      font-size: 14px;
      align-items: center;
      .radio-btn {
        height: 1.5rem;
        width: 1.5rem;
        i {
          color: $color-primary;
          background: white;
          padding: 4px;
          border: 2px solid $color-primary;
          min-width: 1.5rem;
          min-height: 1.5rem;
          display: flex;
        }
        &.checked {
          i::after {
            font-size: 16px;
            content: "\f00c";
            display: block;
            color: $color-body-text;
            font-family: $font-awesome;
            font-weight: 900;
            line-height: 0.5;
          }
        }
      }
    }
    input {
      visibility: hidden;
      height: 0;
      margin: 0 0 0.5rem;
    }
  }

  .form-grid-cols {
    grid-gap: 0.5rem;
    display: grid;
    grid-template-columns: auto 1fr;

    label {
      margin: 0;
      place-self: center;
    }

    select,
    input {
      margin: 0;
      max-width: 100%;
    }

    &.gap-half {
      grid-gap: 0.5rem;
    }

    &.reverse {
      grid-template-columns: 1fr auto;
    }

    &.col-2 {
      grid-template-columns: repeat(2, 1fr);
    }
    &.col-4 {
      grid-template-columns: repeat(4, 1fr);
    }
    &.full-col {
      grid-template-columns: 1fr;
      label {
        margin: 0;
        place-self: start;
      }
    }
    @media only screen and (max-width: $small) {
      grid-gap: 0.75rem;
    }
  }

  .form-flex-row {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;

    > div:first-child {
      min-width: 250px;
      width: 50%;
      max-width: 400px;
    }
  }

  .form-flex {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    margin-left: 0 !important;

    label,
    input,
    select {
      margin: 0;
    }
  }

  .bold {
    label {
      font-weight: 900;
    }
  }

  .smartyResponse {
    position: relative;
    background: $color-nav-green;
    border: 2px solid $color-primary;
    border-radius: 0.5rem;
    position: absolute;
    width: 100%;
    z-index: 500;
    top: 3.5rem;
    padding: 0.5rem;
    display: grid;
    grid-gap: 0.5rem;
    -webkit-box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.25);

    &::after,
    &::before {
      bottom: 100%;
      left: 93%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &::after {
      border-color: rgba(208, 245, 246, 0);
      border-bottom-color: $color-nav-green;
      border-width: 15px;
      margin-left: -15px;
    }
    &::before {
      border-color: rgba(0, 202, 207, 0);
      border-bottom-color: $color-primary;
      border-width: 18px;
      margin-left: -18px;
    }

    button {
      text-align: left;
      background-color: white;
      border-radius: 8px;
      padding: 1rem;
      line-height: normal;
      &:hover {
        background-color: $color-primary;
        color: white;
      }
    }
  }

  &.product-options {
    padding: 0;
    select {
      width: auto;
      border-radius: 0;
      min-width: 125px;
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
    }
    .cta {
      margin-bottom: 0.5rem;
    }
    .label-cta {
      display: inline-block;
      margin-left: 0.5rem;
      font-size: 1.25rem;
    }
  }

  .subFrequencyForm {
    grid-gap: 0.5rem;
    display: grid;
    grid-template-columns: auto 1fr;

    @media only screen and (max-width: $small) {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto 1fr;
      justify-content: start;
      align-items: center;

      &.editing {
        grid-gap: 0.5rem;
        display: grid;
        grid-template-columns: auto 1fr;

        label {
          margin: 0;
          place-self: center;
        }

        select,
        input {
          margin: 0;
          max-width: 100%;
        }
      }
    }

    label {
      margin: 0;
      grid-row: 1 / 2;
      grid-column: 1 / 2;

      @media only screen and (min-width: $small) {
        place-self: center;
      }
    }

    span {
      text-transform: capitalize;
    }

    select,
    input {
      margin: 0;
      max-width: 100%;

      @media only screen and (max-width: $small) {
        grid-row: 2 / 3;
        grid-column: 1 / 3;

        max-width: 80%;
      }
    }
  }

  @media only screen and (max-width: $small) {
    .form-flex {
      &.mbl-left {
        justify-content: flex-start;
      }
      &.mbl-collapse {
        grid-gap: 0;
      }
    }
    .mbl-full {
      input,
      select {
        width: 100% !important;
      }
    }
    .mbl-hide {
      label {
        visibility: hidden;
        height: 0;
        margin: 0;
        padding: 0;
        font-size: 0;
      }
    }
    .mbl-expand {
      button {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: $small) {
    &.acc_flex_col {
      label {
        margin-bottom: 0;
      }
    }
  }

  .recurly-hosted-field {
    border: 2px solid $color-primary !important;
    border-radius: $border-radius !important;
    max-width: 100%;
    height: 44px !important;
    margin: 0 !important;
    padding: 8px 10px !important;
    background-color: #fff !important;
    box-sizing: border-box !important;
  }
}
