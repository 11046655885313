.collection-body {
  &.filters {
    .collectionWrapper {
      margin-top: 1rem;
    }

    .collectionFilters {
      margin-top: 1rem;
    }
    @media screen and (min-width: $large) {
      display: grid;
      grid-template-columns: 300px 1fr;
      grid-gap: 1rem;

      .collectionWrapper {
        margin-top: 0;
      }
    }

    @media only screen and (max-width: $pre-large) {
      display: flex;
      flex-direction: column;

      .collectionFilters {
        margin: 1rem auto 0;

        h2 {
          font-size: 1.5rem;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          line-height: normal;
          width: 280px;
          grid-gap: 1rem;
          margin: 0 auto;
          cursor: pointer;
          color: $color-white;
          background-color: $color-purple;
          padding: 0.25rem;
          border-radius: 1.5rem;
          margin-bottom: 1rem;

          &::after {
            display: block;
            font-size: 1rem;
            font-family: $font-awesome;
            font-weight: 400;
            color: $color-white;
            content: "\f054";
          }

          &.active::after {
            transform: rotate(90deg);
          }
        }

        .filterOpts {
          margin: 0.5rem auto;
          display: none;
          &.active {
            padding: 0.5rem 0rem 0.5rem 1rem;
            border-radius: 10px;
            color: #fff;
            display: block;
            background-color: $color-secondary;
          }
        }
      }
    }
  }
}
