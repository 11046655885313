.wrapper {
  margin: 0 auto;
  padding: 1rem;
  width: 100%;
  max-width: $site-width;
}
.wrapper_grid {
  @extend .wrapper;
  display: grid;
  place-items: center;
  text-align: center;
}

.hidden {
  display: none;
}

.fixed {
  position: fixed;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.primary {
  color: $color-primary;
}
.dotted {
  border-bottom: 1px dotted $color-primary;
}

.dottedThick {
  border-bottom: 2px dotted $color-primary;
}
.solid {
  border-bottom: 1px solid $color-primary;
  &.grey {
    border-bottom: 1px solid $color-med-grey;
  }
}
.top-solid {
  border-top: 1px solid $color-primary;
  &.grey {
    border-top: 1px solid $color-med-grey;
  }
}

.thickTop {
  border-top-width: 2px;
}

.highlight {
  background: $highlight;
}

.margin-0 {
  margin: 0;
}
.margin-1-auto {
  margin: 1rem auto;
}
.bottom-1 {
  margin-bottom: 1rem;
}
.top-1 {
  margin-top: 1rem;
}
.top-1-5 {
  margin-top: 1.5rem;
}
.pad-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

ul {
  &.no-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin: 0 auto 0.5rem;

  &.min {
    max-width: max-content;
  }
  &.table-auto {
    table-layout: auto;
  }

  .full {
    width: 100%;
  }
  .right {
    padding-left: 0.5rem;
    white-space: nowrap;
  }
  .center {
    padding: 0 0.5rem;
    white-space: nowrap;
  }
  .even {
    width: 10%;
  }
  .top {
    vertical-align: top;
  }

  th {
    font-family: $neue-haas-unica-medium;
    font-weight: normal;
    &.hide {
      visibility: hidden;
      height: 0;
      margin: 0;
      padding: 0;
      font-size: 0;
    }
  }

  td {
    &.strong {
      font-weight: 900;
      font-size: 1.2rem;
    }
  }
  @media only screen and (min-width: $small) {
    .min-150 {
      min-width: 150px;
    }

    td {
      &.strong {
        font-weight: 900;
      }
    }
  }
}

// MODAL //
.cover {
  position: fixed;
  z-index: 1100;
  top: 0;
  bottom: 0;
  width: 100vw;

  .fuzzy {
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0/0.25);
    backdrop-filter: blur(4px);
    position: fixed;
  }
}

.dialog-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;

  .dialog-panel {
    position: relative;
    background: white;
    border: 1px solid $color-primary;
    border-radius: 1rem;
    padding: 1rem;
    text-align: center;
    max-height: 95vh;
    max-width: 95vw;
    overflow-y: auto;

    h2.alert {
      background-color: white;
    }
  }

  .dialog-content {
    display: flex;
    grid-gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    &.nogrid {
      display: block;
    }
  }
  h2 {
    line-height: 1;
    margin: 0 0 0.5rem;
    padding: 0 1rem 0.5rem;
    border-bottom: 1px solid $color-med-grey;
  }
  &.loader {
    h2 {
      color: $color-purple;
      border: none;
    }
    .loading {
      min-height: 240px;
      min-width: 240px;
    }
  }

  p {
    line-height: 1.2;
  }
}

.max-200 {
  max-width: 200px;
}
.max-500 {
  max-width: 500px;
}
.min-400 {
  width: 400px;
  max-width: 100%;
}

.card {
  border-radius: 0.5rem;
  padding: 0.5rem;
  p {
    margin: 0 0 0.5rem;
  }

  li {
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: $small) {
  .full-small {
    width: 100%;
  }
}
@media only screen and (min-width: $small) {
  article {
    min-height: 250px;
  }
  .mobile-only {
    display: none;
  }
  .wrapper {
    padding: 1rem $gutter;
  }
  .dialog-wrapper {
    .dialog-panel {
      max-width: 800px;
    }
  }
  .dt-right {
    text-align: right;
  }
}

.breadcrumbs {
  margin: 1rem 0;
  font-size: 14px;
  margin-top: 0rem;
  a {
    color: $color-black;
    text-decoration: underline;
  }
}
.custom-counter {
  list-style: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
  li {
    counter-increment: item;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: auto 1fr;
  }
  li:before {
    margin-right: 1rem;
    content: counter(item);
    background: $color-primary;
    border-radius: 100%;
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.4rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    align-self: start;
  }
}

.alert {
  color: white;
  background-color: $color-red;
  border: none;
  border-radius: 0.5rem;
  padding: 10px;
  margin: 0 0 1rem;
  display: grid;
  grid-gap: 1rem;

  h2 {
    margin: 0;
    font-size: 16px;
    display: grid;
    grid-template-columns: 30px 1fr;
    grid-gap: 8px;
    line-height: 1;
    align-items: center;

    i {
      font-size: 30px;
    }
  }
  a {
    color: white;
    text-decoration: underline;
  }

  &.alert-success {
    text-align: center;
    color: $color-dark-green;
    background-color: $color-light-green;
    border-radius: 0.5rem;
    padding: 0.5rem;
    h2 {
      display: block;
      line-height: 1.2;
    }
    a {
      color: $color-dark-green;
    }
  }
  &.alert-lt {
    background-color: $color-red-light;
    color: $color-body-text;
    a {
      color: $color-body-text;
      text-decoration: underline;
    }
  }
}
.errorpage {
  margin: 1rem auto;
  text-align: center;
}

.loadMore {
  display: flex;
  align-items: center;
  text-align: center;
  color: $color-purple;
  gap: 1rem;
  &::before,
  &::after {
    content: "";
    border: 1px solid $color-purple;
    flex: 1;
    opacity: 0.5;
  }
}

.seal {
  position: absolute;
  top: -40px;
  left: 75%;
  height: 110px;
  width: 110px;
  background-color: #fbd833;
  mask-image: url("/seal.svg");
  mask-size: 110px;
  p {
    line-height: 0.9;
    font-family: $serenity;
    font-weight: 900;
    margin: 0;
  }
}
