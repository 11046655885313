@import "@styles/helpers/variables";

.cartUpsellContainer {
  position: absolute;
  left: -279px;
  // top: 0;
  top: 56px;
  width: 279px;
  height: 100%;
  display: block;
  background-color: $color-background-lt-green;
  text-align: center;
  padding: 32px 0;
  overflow: scroll;
  overflow-x: hidden;
  // display: none;

  .cartUpsellHeader {
    font-family: $neue-haas-unica-medium;
    font-size: 1.25rem;
    line-height: 1.5rem;
    max-width: 240px;
    margin: 0 auto 31px;
    color: $color-primary;
    padding: 0 20px;
  }

  .cartUpsellItemsWrapper {
    padding: 0 1rem;
    display: grid;
    grid-gap: 1rem;
  }
}

.freeShippingBannerLink {
  display: flex;
  flex-direction: row;
  // position: absolute;
  position: relative;
  // top: -56px;
  top: 0;
  left: 0;
  background-color: $color-red;
  width: 100%;
  height: 56px;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;

  &:hover {
    color: $color-white;
  }

  @media screen and (min-width: $large) {
    left: -279px;
    width: calc(100% + 279px);
  }

  @media screen and (max-width: $small) {
    // height: 96px;
    // padding: 0 60px;
    flex-direction: column;

    .upsellBold {
      display: none;
    }
  }

  @media screen and (max-width: $xsmall) {
    font-size: 0.9rem;
  }

  .freeShippingBanner {
    color: $color-white;
  }
}
