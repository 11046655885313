@import "@styles/helpers/variables";

// Badges //
.badges {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  .badge {
    width: 162px;
    height: auto;
    margin: 0.5rem;
    background-color: $color-white;
  }
}
