@charset 'UTF-8';

// Fonts
@import "./fonts/fonts";
@import "./fonts/icons";
@import "./fonts/typography";

@import "./helpers/variables";
@import "./helpers/mixins";
@import "./helpers/functions";
@import "./elements/base";
@import "./elements/buttons";
@import "./elements/forms";
@import "./components/formbuilder";
@import "./elements/grid";
@import "./effects/transitions";
@import "./effects/loader";

// Shop //
@import "./components/shop/product";
@import "./components/shop/collection";

// Yotpo
@import "./yotpo//bottomline";
@import "./yotpo/reviews";
@import "./yotpo/rewards";

// Slick carousel
@import "./elements/carousel";

// CMS Styles
@import "./cms_styles/_large-hero";
@import "./cms_styles/_footer.scss";
@import "./cms_styles/_impact.scss";
@import "./cms_styles/_collection.scss";

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  background-color: white;
}
main {
  display: block;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}
strong,
b {
  font-family: $neue-haas-unica-medium;
  font-weight: normal;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  &.natural {
    width: auto;
  }
}

.simple_header {
  padding-top: 95px;
}
