.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-height {
  transition-property: max-height;
  overflow: hidden;
}
.ease-linear {
  transition-timing-function: linear;
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.duration-150 {
  transition-duration: 150ms;
}
.duration-300 {
  transition-duration: 300ms;
}

.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 100;
}

.height-0 {
  max-height: 0;
}
.height-full {
  max-height: 100%;
}
.loader_height {
  height: 50vh;
}
.rotate-180 {
  transform: rotate(180deg);
  transition-duration: 200ms;
}
.dont-show {
  visibility: hidden;
  max-height: 0;
}
