$product-main-medium: 991px;

.slick-slider {
  img,
  video {
    height: auto;
    margin: auto;
    background-color: $color-white;
    border-radius: 10px;
  }
}

.slick-slide {
  padding: 5px;

  & > div {
    background-color: white;
    border-radius: 10px;
    display: flex;
  }

  a {
    color: $color-black;
    height: fit-content;
  }
}

//Products Section Slick Slider
.carousel {
  width: 405px;
  margin: 10px 40px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: $product-main-medium) {
    width: 90vw;
    margin: auto;
  }

  .slick-prev {
    background-color: $color-deep-purple !important;
    color: white !important;
    font-size: 12px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    left: -30px !important;
    z-index: 1;

    &:before {
      content: none !important;
    }
  }

  .slick-next {
    background-color: $color-deep-purple !important;
    color: white !important;
    font-size: 12px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    right: -30px !important;

    &:before {
      content: none !important;
    }

    i {
      margin-left: 2px;
    }
  }

  .slick-disabled {
    display: none !important;
  }

  .slick-slider {
    img,
    video {
      height: 100px;

      @media screen and (max-width: $product-main-medium) {
        height: 200px;
      }

      @media screen and (max-width: $xsmall) {
        height: 100px;
      }
    }

    .slick-slide {
      @media screen and (max-width: $product-main-medium) {
        min-width: 200px;
      }

      @media screen and (max-width: $xsmall) {
        min-width: 100px;
      }

      & > div {
        min-width: 100px;
        height: 100px;

        @media screen and (max-width: $product-main-medium) {
          height: 200px;
        }

        @media screen and (max-width: $xsmall) {
          height: 100px;
        }
      }
    }

    .slick-list {
      height: 120px;

      @media screen and (max-width: $product-main-medium) {
        height: 250px;
      }

      @media screen and (max-width: $xsmall) {
        height: px;
      }
    }

    .slick-dots {
      position: initial;

      li {
        margin: 0 5px;

        i {
          color: $color-white;
          border: 1px solid $color-primary;
          border-radius: 50%;
        }

        &.slick-active {
          .fa-circle {
            background-color: $color-primary;
            color: $color-primary;
          }
        }

        .fa-play {
          color: $color-primary;
          border: none;
        }
      }
    }
  }

  .player-wrapper {
    div:first-of-type {
      height: 100px !important;
      width: auto !important;
      margin: auto;
      background-color: $color-white;
      border-radius: 10px;

      @media screen and (max-width: $product-main-medium) {
        height: 200px !important;
      }

      @media screen and (max-width: $xsmall) {
        height: 150px !important;
      }
    }
  }
}

// Recommended Products Slick Carousel
.recommended-section {
  .slick-slider {
    img,
    video {
      height: 180px;
    }

    .rec-description {
      font-size: 16px;
      font-family: $neue-haas-unica-medium;
      margin-top: 1rem;
      text-align: center;
      width: 180px;
      line-height: 1.2;
    }

    .slick-slide {
      & > div {
        height: 180px;
      }

      a {
        color: $color-black;
        height: fit-content;
      }
    }

    .slick-list {
      height: 300px;
    }
  }
}
