/* ============================================================================
  Buttons and links
============================================================================== */

@mixin buttons($bgcolor) {
  background-color: $bgcolor;
  &:hover {
    background-color: darken($bgcolor, 25);
    color: $color-white;
  }
  &:active,
  &:focus {
    background-color: darken($bgcolor, 30);
    outline: 1px solid $bgcolor;
  }
}

.image-link {
  display: block;
}

button {
  &.disabled {
    cursor: default;
    color: $color-white !important;
    background-color: $color-disabled !important;

    &:hover,
    &:active,
    &:focus {
      color: $color-white !important;
      background-color: $color-disabled !important;
    }
  }
}

.close-icon {
  background: white;
  padding: 0;
  width: 30px;
  height: 30px;
  font-size: 1.25rem;
  line-height: 1;
  transition: none;

  &.corner {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1000;
  }

  &::before {
    height: 23px;
    width: 23px;
    text-align: center;
    font-family: $font-awesome;
    font-weight: 900;
    color: $color-black;
    content: "\f00d";
  }
  &:hover {
    background: $color-black;
    &::before {
      color: white;
    }
  }
}

.customCloseIcon {
  background: white;
  padding: 0;
  width: 48px;
  height: 48px;
  font-size: 1.25rem;
  line-height: 1;
  transition: none;

  &.corner {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1000;
    border-radius: 3rem;
  }

  &::before {
    height: 48px;
    width: 48px;
    text-align: center;
    font-family: $font-awesome;
    font-size: 2rem;
    font-weight: 200;
    color: $color-purple;
    content: "\f00d";
  }
}
