@import "@styles/helpers/variables";

.cartModal {
  width: 100%;
  position: fixed;
  background-color: #ffffff;
  z-index: 5000;
  top: 0;
  right: 0;
  height: 100%;

  @media screen and (min-width: $large) {
    max-width: 735px;
  }

  .cartGrid {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
  }
}

.showUpsell {
  @media screen and (min-width: $large) {
    height: calc(100% - 56px);
  }
}
