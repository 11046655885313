.grid-col {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;

  &.inline {
    display: inline-grid;
  }
}

.grid-single {
  display: grid;
}
.grid-single-col {
  display: grid;
  align-content: center;
  grid-gap: 0.5rem;
  justify-content: center;
}

.grid-card {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.span-2 {
  grid-column: 1 / span 2;
}
.span-3 {
  grid-column: 1 / span 3;
}
.span-4 {
  grid-column: 1 / span 4;
}
.place-2 {
  grid-column: 2 / span 1;
}
.place-3 {
  grid-column: 3 / span 2;
}
.place-4 {
  grid-column: 4 / span 1;
}

@media only screen and (max-width: $small) {
  .grid-card {
    grid-template-columns: auto;
  }
  .mbl-full {
    .span-2,
    .place-2 {
      grid-column: 1;
    }
  }
  .grid-single {
    margin-top: 1rem;
  }
}

.flex-wrap {
  display: flex;
  grid-gap: 1rem;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.flex_col {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  > * {
    flex: 1 1 175px;
  }

  &.even {
    > * {
      flex: 1 1 48%;
    }
  }
  &.min-400 {
    > * {
      width: 400px;
    }
    @media only screen and (max-width: $small) {
      > * {
        width: 100%;
      }
    }
  }
  &.max-full {
    max-width: 100%;
    input {
      max-width: 100%;
      line-height: 1.2;
    }
  }

  &.first-full {
    > *:first-child {
      flex: 1 1 100%;
      padding-bottom: 1.5rem;
      margin-bottom: 0;
      border-bottom: 1px solid $color-primary;
    }
  }
}

.flex-between {
  justify-content: space-between;
}

.flex-center {
  align-items: center;
}
